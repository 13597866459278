<script>
import { mapActions } from "vuex";
import IconBase from "../components/icons/_icon-base.vue";
import IconVivizinho from "../components/icons/_icon-vivizinho.vue";

export default {
  name: "Vendedor",
  components: {
    IconBase,
    IconVivizinho,
  },

  async mounted() {
    let vendedor = this.$route.query.vendedor;
    const sellers = await this.getSellers();

    const isValidSeller = !!sellers.find(val => val.value == vendedor);
    vendedor = isValidSeller ? vendedor : undefined;

    this.changeSeller(vendedor);
    this.setSellers(sellers);

    return this.$router.push({
      path: "/",
      query: {
        ...this.$route.query,
        vendedor,
        sellerMode: true,
        vitrine: "VENDEDOR",
      },
    });
  },
  methods: {
    ...mapActions({
      fetchApi: "api",
      setSellers: "catalog/setSellers",
      changeSeller: "changeSeller",
    }),

    async getSellers() {
      const projectId = process.env.VUE_APP_PROJECT_ID ?? "4918426000267988585";
      const response = await this.fetchApi({
        entity: "hub",
        action: "sellers",
        params: { projectId },
      });
      if (response.data.status === "success") {
        const sellers = response.data.data;
        return sellers
          .map(val => ({
            value: val.idVendedor,
            text: val.name,
          }))
          .toSorted((a, b) => {
            if (a.text > b.text) return 1;
            if (a.text < b.text) return -1;
            return 0;
          });
      }
    },
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-primary"
  >
    <icon-base :size="16"><icon-vivizinho /></icon-base>
  </div>
</template>
